
.table-title th {
  color: black;
  font-weight: bold;
}

table {
  border-left: 0px solid;
  border-right: 0px solid;
  border-bottom: 0px solid;
  border-top: 0px solid;
}

tr:hover {
    background-color: orange;
}
tr {
  text-align: center;
}
